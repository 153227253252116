@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import 'react-toastify/dist/ReactToastify.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .collapsed {
    visibility: collapse;
  }

  .inherit-height {
    height: inherit;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.icon-reset {
  fill: none;
}

.icon-reset.active {
  fill: none !important;
  stroke: white;
}

@layer base {
  html {
    font-family: 'Roboto', sans-serif;
  }
  a{
    @apply underline cursor-pointer;
  }
  .menu-item-icon > * {
    @apply fill-primary-700;
  }
  .menu-item-icon.active > * {
    @apply fill-grayscale-white;
  }

  .action-list-icon > * {
    @apply fill-grayscale-black;
  }

  .action-list-icon.disable > * {
    @apply fill-grayscale-300;
  }

  .action-list-icon.active > * {
    @apply fill-primary-400;
  }
  .thumb {
    @apply pointer-events-none absolute h-0 w-full outline-none  px-7;
  }
  .thumb::-webkit-slider-thumb {
    @apply bg-grayscale-white border-2 border-solid border-primary-400  rounded-full 
           cursor-pointer h-6 w-6 mt-2 pointer-events-auto;
  }
  .thumb,
  .thumb::-webkit-slider-thumb {
    @apply appearance-none;
  }
  input::-webkit-outer-spin-button,

  input::-webkit-inner-spin-button {

    @apply appearance-none;

  }
}
